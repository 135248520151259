<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap v-if="disciplineList">
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Disciplinary Actions</span>
          <v-spacer></v-spacer>
          <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-2>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  label="From Date"
                  outlined
                  class="rounded-xl"
                  hide-details
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                no-title
                @input="menu11 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-2>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="To Date"
                  outlined
                  hide-details
                  dense
                  class="rounded-xl"
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start v-if="disciplineList.length > 0">
          <v-flex
            xs12
            align-self-center
            v-for="(item, i) in disciplineList"
            :key="i"
            fill-height
            px-4
            py-2
          >
            <v-card tile outlined>
              <v-layout wrap justify-end py-2>
                <v-flex
                  xs4
                  sm3
                  align-self-start
                  text-left
                  pa-3
                  v-if="item.employeeid"
                >
                  <span style="font-family: kumbhRegular">{{
                    item.create_date.slice(0, 10)
                  }}</span>
                </v-flex>
                <v-flex xs8 sm9 align-self-start text-left pa-3>
                  <span
                    v-if="item.disciplinaryAction"
                    style="font-family: kumbhRegular"
                    >{{ item.disciplinaryAction.slice(0,40) }}

                    <br />
                    <v-dialog v-model="item.view" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          style="font-family: kumbhBold"
                          class="text-none"
                          text
                          plain
                          :ripple="false"
                          depressed
                          dark
                          title="View more"
                          v-on="on"
                          v-bind="attrs"
                          color="#005f32"
                        >
                          <span class="vhover"> View </span>
                        </v-btn>
                      </template>
                      <v-card class="pa-4 pt-8">
                        <v-card-text>
                          <span class="kumbhRegular">
                            {{ item.disciplinaryAction }}
                          </span>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </span>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-start v-else>
          <v-flex xs12 align-self-center pa-12>
            <span>No data found!</span>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      disciplineList: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      disciplinaryAction: null,
      employeeList: null,
      employee: null,
      employeeid: null,
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
    employeeid() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },

    fromDate() {
      if (this.currentPage > 1) this.currentPage = 1;

      this.getData();
    },
    toDate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
  },
  components: {
    // ImageComp,
  },
  mounted() {
    if (this.fromDate) {
      // this.fromDate.setDate(this.fromDate.getDate());
      this.fromDate.setDate(this.fromDate.getDate() - 31);
      this.fromDate = this.fromDate.toISOString().substr(0, 10);
    }
    if (this.toDate) {
      // this.toDate = null;
      this.toDate.setDate(this.toDate.getDate());
      this.toDate = this.toDate.toISOString().substr(0, 10);
    }
    this.getData();
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/employee/disciplinaryAction/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.currentPage,
          limit: 20,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.disciplineList = response.data.data;
            this.totalData = response.data.totalLength;
            this.pages = Math.ceil(this.totalData / response.data.limit);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },
  },
};
</script>
